<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0">
      <skeleton-list v-if="$store.state.accounts.LoaderMe || $store.state.documents.retrieveLoader" />
      <template v-else>
        <PermissionDenied v-if="checkPermissionDTE" />
        <template v-else>
          <v-empty-state
          :class="{'mt-n2' : routeList.includes($route.name)}" v-if="!documentsList.length || (!routeList.includes($route.name) && Object.keys($route?.query).length === 0)" type="documents"
          id="documentos"
          :customClass="routeList.includes($route.name) ? 'mt-n6 pb-2' : 'mt-n8'"
          :isFree="routeList.includes($route.name)"
          title="No existen documentos para mostrar"
          :subDescription="!hiddenBtnEmpty ? null : subDescription"
          :height="routeList.includes($route.name) ? 199 : 0"
        >
            <template v-slot:actions>
              <template>
                <v-btn @click="$emit('activeBtnFilter')" outlined :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="latestRecords()" v-if="hiddenBtnLastestRecords && hiddenBtnEmpty" color="blue-500" :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <template v-else>
            <v-data-table
              :class="[{'table-not-rounded' : ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve'].includes($route.name)}, {'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
              :headers="!['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes($route.name) ? currentHeader : headersDetail"
              :items="documentsList"
              :items-per-page="pageSize || pagination"
              hide-default-header
              disable-sort
              hide-default-footer
              @click:row="(documentsList) => { goTo(documentsList.id) }"
              style="cursor: pointer"
              mobile-breakpoint="300"
              item-key="id"
              v-model="selected"
              :show-select="!['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes($route.name)"
              checkbox-color="blue-500"
              :fixed-header="!['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes($route.name)"
              :height="heightTable"
              >
              <!-- header -->
              <template v-slot:header="{ props: { headers } }">
                <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
                <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
              </template>
              <!-- end header -->
              <template v-slot:[`item.customer`]="{item}">
                <template v-if="!!Object.keys(item.customer || {}).length">
                  <router-link :to="`/documents/${item.id}`" class="grey-700--text text-decoration-none">
                    <span v-if="((item || {}).customer || {}).name" class="d-inline-block text-truncate" :class="routeList.includes($route.name) ? 'body-2' : 'body-1 mb-n2'" style="max-width: 96%;">{{((item || {}).customer || {}).name}}</span>
                    <span :class="`${routeList.includes($route.name) ? 'body-2' : 'body-1'}, text--disabled font-italic`" v-else>Sin especificar</span>
                    <span class="caption grey-300--text d-block" v-if="((item || {}).customer || {}).taxpayer.tax_id">{{$t('generals.RUT')}} {{item.customer.taxpayer.tax_id}}</span>
                    <span :class="`${routeList.includes($route.name) ? 'body-2' : 'body-1'}, text--disabled font-italic`" v-else>Sin especificar</span>
                  </router-link>
                </template>
                <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </template>
              <template v-slot:[`item.document_type`]="{item}">
                <router-link :to="`/documents/${item.id}`" class="grey-700--text text-decoration-none">
                  <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'" style="white-space: normal !important">{{item.document_type | nameDocumentsType}}</span>
                </router-link>
              </template>
              <template v-slot:[`item.number`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{$store.state?.auth?.account?.country === 'PE' ? `${item.prefix} - ${item.number}` : item.number }}</span>
              </template>
              <template v-slot:[`item.date`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.date | date}}</span>
              </template>
              <template v-slot:[`item.created`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.created | dateTimeSecond}}</span>
              </template>
              <template v-slot:[`item.status`]="{item}">
                <chip-status :status="item.status ? item.status.code : 'PDR'" :isTooltip="true" />
              </template>
              <template v-slot:[`item.customer_response`]="{item}">
                <chip-status v-if="item.customer_response?.code" :status="item.customer_response?.code ?? null" type="purchases" :isTooltip="true" />
                <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </template>
              <template v-slot:[`item.total`]="{item}">
                <span :class="routeList.includes($route.name) ? 'body-2' : 'body-1'">{{item.total | currency(item.document_type)}}</span>
                <span class="grey-300--text caption ml-1">
                  <template v-if="Object.keys(((item || {}).exchange || {})).length">{{item.exchange.currency_from || item.exchange.currency_to || item.exchange.currency}}</template>
                  <template v-else>{{item.document_type | firstDocumentsTypeCurrency}}</template>
                </span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-n4" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="grey-300--text" size="24">mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-3" @click="renderDocument('documents', null, 'pdf', item.id)" :ripple="false">
                      <v-list-item-title class="body-2"><v-icon color="grey-500" size="20" left>mdi-text-box</v-icon>Ver PDF</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="px-3" @click="downloadFile('documents', $store.state?.auth?.account?.country === 'PE' ? `${item.prefix} - ${item.number}` : `${item.document_type}_${number}`, null, 'xml', item.id)" :ripple="false">
                      <v-list-item-title class="body-2"><v-icon color="grey-500" size="20" left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <VMainPagination titleShowAll="Ver todos los documentos" :count="documentsList?.length"  redirect="DocumentsList"  />
          </template>
        </template>
      </template>
      <!-- dialog pdf -->
      <v-dialog v-model="modalRender" width="700" scrollable persistent no-click-animation overlay-color="grey-500">
        <render-pdf :documentType="current.document_type | nameDocumentsType" :number="number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :key="current.number" :formatPDF.sync="formatPDF" :showFormat="true" />
      </v-dialog>
      <!-- end dialog pdf -->
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import RenderPdf from '@/components/files/RenderPdf'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ChipStatus from '@/components/commons/ChipStatus'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    ChipStatus,
    RenderPdf,
    VEmptyState,
    SkeletonList,
    VTableHeaders,
    VMainPagination,
    PermissionDenied
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin,
    RenderViewMixin
  ],
  props: {
    activeDialogExport: {
      type: Boolean,
      default: false
    },
    headerList: {
      type: Array,
      default: () => ([])
    },
    pageSize: {
      default: null
    }
  },
  data: () => ({
    hiddenBtnLastestRecords: true,
    list: [],
    toggleDropdown: null,
    formatPDF: 1,
    dialogExport: false,
    dialogExportProgress: false,
    resetCheckbox: 0,
    isSelected: null,
    selected: [],
    current: {},
    widthList: [266, 170, 100, 100, 100, 80, 46],
    widthDetailList: [80, 150, 50, 55, 50, 50, 16],
    routeList: ['PosRetrieveUpdate', 'BranchRetrieveUpdate', 'DocumentsRetrieve', 'PurchaseRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve'],
    headersDetail: [
      { text: 'Cliente', value: 'customer', sortable: true },
      { text: 'Tipo de documento', value: 'document_type', sortable: true },
      { text: 'Folio#', value: 'number', align: 'end', sortable: true },
      { text: 'Fecha del doc.', value: 'date', align: 'end', sortable: true },
      { text: 'Estado SII', value: 'status', show: true, disabled: false },
      { text: 'Estado comercial', value: 'customer_response', show: true, disabled: false },
      { text: 'Total', value: 'total', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end', show: true}
    ]
  }),
  computed: {
    subDescription () {
      return this.hiddenBtnLastestRecords ? `Filtra los resultados o selecciona \"Últimos registros\" <span class=\"d-block\"> para ver los documentos del último mes.</span>` : `No se encontraron documentos del último mes. <span class=\"d-block\"> Intenta cambiar los filtros para buscar otros resultados.</span>`
    },
    currentHeader () {
      let list = this.list
      list.forEach((item, index) => {
        if (this.routeList.includes(this.$route.name)) {
          if (item.value === 'number') item.text = this.$t('generals.Folio#')
          if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'type') item.width = 180
        }

        if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'number') {
          item.align = 'start'
          item.width = 160
          item.text = this.$t('generals.Folio #')
        }
      })
       return list
    },
    ...mapState({
      count: state => state.documents.documentsCount,
      documentsList: state => state.documents.documentsList
    }),
    heightTable () {
      if (this.documentsList.length < 17 && this.$vuetify.breakpoint.height >= 900) return 'auto' // this.$vuetify.breakpoint.height <= 900 &&
      if (['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes(this.$route.name)) return 'auto'
      return 'calc(100vh - 275px)'
    },
    number () {
      return this.$store.state?.auth?.account?.country === 'PE' ? `${this.current.prefix} - ${this.current.number}` : this.current.number
    },
    checkPermissionDTE () {
      if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return !ownGroupPermissions.filter(({permission}) => permission === 'view_document').length
      }
      return false
    },
    hiddenBtnEmpty() {
      if (Object.keys(this.$route.query)?.length === 1 && this.$route.query?.ordering) return true
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!!this.documentsList.length) return false
      return false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== this.documentsList.length) this.resetCheckbox += 1

      this.$emit('update:selectedDocuments', this.selected)
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.documentsList
        this.isSelected = true
      } else if (val === '') this.selected = []
      this.$emit('update:toggleDropdown', val)
    },
    activeDialogExport (val) {
      this.dialogExport = val
    },
    formatPDF: {
      handler (val) {
        if (val === 1) this.renderDocument('documents', null, 'pdf', this.current.id)
        else {
          this.renderDocument('documents', 'documents', 'pdf', this.current.id, { thermal_format: val})
        }
      },
      inmediate: true,
      deep: true
    },
     headerList () {
      this.list = [...this.headerList].filter((item) => item.show)
    },
    '$route.query'(val) {
      if (Object.keys(val)?.length === 1 && val.ordering) {
        this.hiddenBtnLastestRecords = this.count
      }
    }
  },
  created () {
    if (Object.keys(this.$route.query)?.length === 1 && this.$route.query.ordering) {
      this.hiddenBtnLastestRecords = this.count
    }
  },
  methods: {
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'DocumentsRetrieve', params: { id: id }})
    },
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.documentsList
    },
    handleExport () {
      this.dialogExport = false
      this.dialogExportProgress = true
      this.$emit('update:activeDialogExport', false)
    }
  }
}
</script>