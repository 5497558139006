<template>
  <v-col class="text-center py-0">
    <v-row class="mt-2" align="center" justify="center">
      <v-img contain :height="height === 0 ? 280 : height" :src="require(`@/assets/empty-state/appAsset--${type}-ax.svg`)" />
    </v-row>
    <div :class="customClass">
      <span class="d-block subtitle-2 grey-700--text" v-if="!isFree">
        <span class="d-block font-weight-bold">{{ !isFilter ? `¿Quieres ver ${id}?` : `No existen ${id} para mostrar` }}</span>
        <span v-if="hiddenDescription" v-html="!isFilter ? `${subDescription ?? 'Filtra para obtener registros.'}` : `${subDescription ?? 'Intenta cambiar los filtro para buscar otros resultados.'}`"></span>
      </span>
      <span class="d-block subtitle-2 grey-700--text" v-else>
        <span class="d-block font-weight-bold">{{ title }}</span>
        {{ description}}
      </span>
    </div>
    <div class="mt-5">
      <slot name="actions" />
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'documents'
    },
    id: {
      type: String,
      default: 'documentos'
    },
    height: {
      type: Number,
      default: 280
    },
    count: {
      type: Number,
      default: 0
    },
    isFree: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    hiddenDescription: {
      type: Boolean,
      default: true
    },
    subDescription: {
      type: String,
      default: null
    }
  },
  computed: {
    isFilter () {
      return this.count === 0 && Object.keys(this.$route?.query).length > 0
    }
  }
}
</script>